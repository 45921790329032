import React from 'react';

const CoeusKeyFrame: React.FC = () => (
  <svg width="100vw" height="100vh" viewBox="0 0 100 100" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
    {/* A rectangle that covers the entire viewport */}
    <rect x="0" y="0" width="100" height="100" fill="blue" />
  </svg>
);

export default CoeusKeyFrame;
