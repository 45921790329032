// App.tsx

import React from 'react';
import { ChakraProvider, Box, Image, Center, Text } from '@chakra-ui/react';
import theme from './themes/theme';
import CoeusKeyFrame from './assets/images/coeus-key-frame';

const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <Box minH="100vh" width="100vw" display="flex" alignItems="center" justifyContent="center">
        <CoeusKeyFrame />
      </Box>
    </ChakraProvider>
  );
}

export default App;
